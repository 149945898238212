@font-face {
  font-family: 'Inter';
  src: local('Inter Bold'), local('Inter-Bold'),
    url('fonts/Inter-Bold.woff2') format('woff2'),
    url('fonts/Inter-Bold.woff') format('woff'),
    url('fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Black'), local('Inter-Black'),
    url('fonts/Inter-Black.woff2') format('woff2'),
    url('fonts/Inter-Black.woff') format('woff'),
    url('fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
    url('fonts/Inter-ExtraBold.woff2') format('woff2'),
    url('fonts/Inter-ExtraBold.woff') format('woff'),
    url('fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraLight'), local('Inter-ExtraLight'),
    url('fonts/Inter-ExtraLight.woff2') format('woff2'),
    url('fonts/Inter-ExtraLight.woff') format('woff'),
    url('fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
    url('fonts/Inter-SemiBold.woff2') format('woff2'),
    url('fonts/Inter-SemiBold.woff') format('woff'),
    url('fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Thin'), local('Inter-Thin'),
    url('fonts/Inter-Thin.woff2') format('woff2'),
    url('fonts/Inter-Thin.woff') format('woff'),
    url('fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Light'), local('Inter-Light'),
    url('fonts/Inter-Light.woff2') format('woff2'),
    url('fonts/Inter-Light.woff') format('woff'),
    url('fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
    url('fonts/Inter-Regular.woff2') format('woff2'),
    url('fonts/Inter-Regular.woff') format('woff'),
    url('fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
    url('fonts/Inter-Medium.woff2') format('woff2'),
    url('fonts/Inter-Medium.woff') format('woff'),
    url('fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Bold.eot');
  src: url('fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Lato-Bold.woff2') format('woff2'),
    url('fonts/Lato-Bold.woff') format('woff'),
    url('fonts/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Regular.eot');
  src: url('fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Lato-Regular.woff2') format('woff2'),
    url('fonts/Lato-Regular.woff') format('woff'),
    url('fonts/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.blod-select-all {
  font-weight: 600;
}
.link-color,
.link-color:hover {
  color: #3300FF;
}
.text-align-end {
  text-align: end;
}
.toggle-switch .ant-form-item-control-input-content {
  display: contents;
}
.sz-breadcrumb {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  margin: 18px 0;
  font-size: 12px;
  font-weight: 400;
}
.sz-breadcrumb span.ant-breadcrumb-link span.current,
.sz-breadcrumb>span:last-child {
  color: #252C32;
}
.sz-breadcrumb span.ant-breadcrumb-link a:hover {
  color: #3300FF;
}
.sz-gray {
  color: #DDE2E4;
}
.sz-breadcrumb span.ant-breadcrumb-separator {
  color: #B0BABF;
  position: relative;
  transform: rotate(315deg);
  display: inline-block;
  margin: 0 8px 0 10px;
}
.sz-breadcrumb span.ant-breadcrumb-separator::after {
  content: '';
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  content: "";
  display: inline-block;
  height: 7px;
  left: -3px;
  position: absolute;
  top: -8px;
  width: 7px;
}
/*Solved issue related to display last > in breadcrumb */
.sz-breadcrumb li:last-child span.ant-breadcrumb-separator::after {
  border-bottom-style: none;
  border-bottom-width: none;
  border-right-style: none;
  border-right-width: none;
}

h1.sz-module-title {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #252C32;
}
.sz-tabs .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.006em;
  color: #252C32;
  border: 0;
}
.sz-tabs .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn span {
  color: #3300FF;
  font-weight: 600;
}
.sz-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  border: 0 !important;
  border-bottom: 3px solid #3300FF !important;
  font-weight: 500;
}
.sz-tabs>.ant-tabs-nav::before,
.sz-tabs>.ant-tabs-nav .ant-tabs-nav-wrap::before {
  display: none;
}
.sz-tabs .ant-tabs-nav-list .ant-tabs-tab {
  border: 0px !important;
}
.last-edited-text {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #727287;
}
.sz-outline-btn {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.04), 0px 0px 0px 1px rgba(60, 66, 87, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: 3px solid #3300FF;
  text-transform: none !important;
  color: #3300FF;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
  height: auto;
}
.sz-outline-btn img {
  margin-right: 4px;
  margin-bottom: 2px;
}
.sz-outline-btn:hover,
.sz-outline-btn:focus {
  color: #3300FF;
  background: #fff;
  border-color: #3300FF;
}
.sz-outline-btn[disabled] img {
  filter: grayscale(1);
  opacity: 0.5;
}
.sz-gray-btn {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.04), 0px 0px 0px 1px rgba(60, 66, 87, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: 3px solid #8E8E8E;
  background: #8E8E8E;
  text-transform: none !important;
  color: #FFFFFF;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
  height: auto;
}

.sz-gray-btn:hover,
.sz-gray-btn:focus {
  color: #FFFFFF;
  background: #8E8E8E;
  border-color: #8E8E8E;
}

.sz-gray-btn-blue {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.04), 0px 0px 0px 1px rgba(60, 66, 87, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: 3px solid #3300FF;
  background: #3300FF;
  text-transform: none !important;
  color: #FFFFFF;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
  height: auto;
}

.sz-gray-btn-blue:hover,
.sz-gray-btn-blue:focus {
  color: #FFFFFF;
  background: #3300FF;
  border-color: #3300FF;
}



.sz-form .ant-form-item-label label {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #252C32;
}
.sz-form .ant-form-item {
  margin-bottom: 10px;
}
.sz-select.ant-select .ant-select-selector {
  background: #EEEEEE;
  border-radius: 20px;
  border-bottom: 0px;
}

.sz-select-salesorder.ant-select .ant-select-selector {
  background: #fff;
  border-radius: 20px;
  border:1px solid #dedede;
}
.sz-select.ant-select .ant-select-selector:hover {
  border-bottom: 0;
}
.sz-select .ant-select-selection-item,
.ant-select-item-option-content {
  color: #252C32;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  /* border-bottom: none !important; */
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #E3EEFD;
  border-radius: 10px;
  margin: 2px 10px;

}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-content {
  color: #3300FF;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 500;
}
.ant-select-selection-placeholder {
  color: #6f7980;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{
  background-color: #EEEEEE !important;
}
.sz-form .ant-form-item-control-input-content .ant-input,
.ant-form-item-control-input-content .ant-input-number-input,
.sz-form .ant-form-item-control-input-content .ant-input:focus,
.ant-form-item-control-input-content .ant-input-number-input:focus {
  border-bottom: 1px solid #DDE2E4;
  color: #252C32;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.ant-switch {
  background-color: #777777;
}
.ant-switch-inner {
  margin: 0 10px 0 25px;
}
.ant-switch-checked {
  background-color: #5EC299;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 10px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3300FF;
  border-color: #3300FF;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: #3300FF;
}

.sz-collapse {
  border: 0;
  background-color: #f5f5f5;
}
.sz-collapse>.ant-collapse-item {
  border: 0;
}
.sz-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 17px 21px;
}
.sz-collapse>.ant-collapse-item>.ant-collapse-header .ant-typography {
  color: #686666;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}
.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  right: 24px;
  top: 22px;
  font-size: 14px;
  transform: rotate(90deg);
}
.ant-collapse>.ant-collapse-item-active>.ant-collapse-header .ant-collapse-arrow {
  transform: rotate(180deg);
}
.sz-collapse .ant-collapse-content {
  border: 0;
}
.file-upload-control .ant-upload.ant-upload-drag {
  border: none;
}
.ant-upload.ant-upload-drag {
  background: #F8F8F8 !important;
  padding: 0 16px;
  border: none;
  border-radius: 0 0 20px 20px;
}
.ant-upload-drag-container {
  padding: 10px 0;
}
.ant-upload.ant-upload-drag p.ant-upload-hint,
.ant-upload.ant-upload-drag p.ant-upload-text {
  color: #a1a2a8;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  border: 2px dashed #E2E6EA;
  border-radius: 10px;
}
.ant-upload-list {
  background: #FFFFFF;
  /* border-radius: 24px; */
  /* box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.07); */
  /* padding: 16px; */
}
.uploaded-document {
  display: flex;
  margin: 5px 0;
}
.uploaded-document img.document {
  margin-right: 15px;
}
.uploaded-document .document-name {
  margin-top: 4px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #242634;
}
.uploaded-document .upload-action {
  margin-left: auto;
}
.uploaded-document .upload-action svg {
  margin: 0 5px;
  fill: #3300FF;
}
.gallery-drag-option a img {
  fill: #3300FF;
}
.gallery-text-content .image-info a {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
}
.gallery-text-content .image-info span, .gallery-text-content .image-info p, .control-list-inn span {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  text-align: left;
}
.gallery-text-content .image-info {
  text-align: left;
}
.ant-popover-message-title h3 {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000;
  margin-top: 3px;
}
.ant-popover-message-title p {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #242634;
  margin-bottom: 5px;
}
.ant-popover-buttons .ant-btn {
  border: 0;
  color: #242634;
}
.ant-popover-buttons .ant-btn:hover {
  border: 0;
  color: #242634;
}
.ant-popover-buttons .ant-btn.ant-btn-primary {
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.04), 0px 0px 0px 1px rgba(60, 66, 87, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: 3px solid #3300FF;
  background: #3300FF;
  text-transform: none !important;
  color: #FFFFFF;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 5px 10px !important;
  height: auto !important;
}

/* Gallery Start */
.gallery-main {
  width: 160px;
  overflow: hidden;
}
.main-image {
  height: 100%;
  width: 100%;
  border: 1px solid #DDE2E4;
  padding: 15px;
  border-radius: 12px;
}
.main-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.thumb-images {
  margin: 15px 0px;
}
.thumb-images span.ant-typography {
  height: 31px;
  width: 35px;
  border: 1px solid #DDE2E4;
  border-radius: 3px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  padding: 2px;
  cursor: pointer;
}
.thumb-images span.ant-typography img {
  width: 100%;
  height: auto;
}
.thumb-images span.ant-typography.add-more-image {
  background: #BEBEBE;
  height: 31px;
  width: 35px;
  border: 1px solid #DDE2E4;
  border-radius: 3px;
  /* display: inline-block; */
  margin-right: 0;
  vertical-align: top;
  padding: 0px 2px 2px 2px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  float: right;
}
/* Gallery End */

/* Location CSS Start */
.sz-locations .ant-form-item {
  margin-bottom: 8px;
}
.sz-locations {
  width: 100%;
  overflow-y: auto;
}
.sz-locations table {
  width: auto;
}
.sz-locations table td {
  border: 1px solid #e0dee4;
  padding: 5px 10px;
}
.sz-locations table table td {
  position: relative;
}
.sz-locations table table td::before {
  position: absolute;
  content: '';
  left: -1px;
  top: -5px;
  height: 10px;
  background: #cdcdcd;
  width: 1px;
  z-index: 1;
}
.sz-locations table table td::after {
  position: absolute;
  content: '';
  left: -1px;
  bottom: -5px;
  height: 10px;
  background: #cdcdcd;
  width: 1px;
  z-index: 1;
}
.sz-locations table table td:first-child::before,
.sz-locations table table td:first-child::after {
  display: none;
}
.sz-locations table td .places-wrapper {
  width: 100%;
  /* overflow-y: auto; */
}
.sz-locations table td table {
  width: 160px;
}
.sz-locations table td table td,
.sz-locations table td table td .ant-row {
  border: 0;
  width: 160px;
}
.sz-locations table td table td {
  border-right: 1px solid #cdcdcd;
}
.sz-locations table td table td:last-child {
  border: none;
}
.sz-locations .sz-fname {
  width: 150px;
}
.sz-locations .sz-fname-value .ant-row {
  width: 175px;
  margin-bottom: 0px;
  padding-left: 15px;
}
.sz-locations .sz-fname-value .ant-row .ant-form-item-control-input {
  min-height: auto;
}
.sz-locations .sz-qty {
  width: 200px;
}
.sz-locations .sz-qty-value .ant-row {
  width: 150px;
  margin: 0px auto 8px;
}
.sz-locations .sz-places {
  position: relative;
}
.sz-locations .sz-qoh input {
  width: 75px;
}
.sz-locations .sz-qoh label {
  width: 40px;
}
.sky-label {
  background: #cfe3fb;
  padding: 7px 15px;
  border-radius: 5px;
  display: block;
  text-align: center;
}
.sz-add-place {
  background: #fff;
  border-radius: 5px;
  position: absolute;
  top: 11px;
  right: 15px;
  color: #3300FF;
  padding: 5px 7px;
}
.sz-locations .sz-place-field .ant-select {
  width: 80px !important;
}
.sz-locations .sz-place-field label {
  width: 50px;
}
.sz-fname-value .store-name .ant-input {
  font-weight: 500;
  margin-bottom: 5px;
}
.sz-fname-value .store-code .ant-input {
  font-style: italic;
  font-weight: 300;
}
/* Location CSS End */

/* Location Quantity CSS Start */
.sz-quantity-locations {
  width: 100%;
  overflow-y: auto;
}
.sz-quantity-locations table td {
  border: 1px solid #e0dee4;
  padding: 5px 10px;
}
.sz-quantity-locations .sz-sname,
.sz-quantity-locations .sz-scode {
  display: block;
}
.sz-quantity-locations .sz-sname {
  font-weight: 600;
  color: #444;
  padding-left: 15px;
}
.sz-quantity-locations .sz-scode {
  font-style: italic;
  font-weight: 300;
  padding-left: 15px;
}
.sz-quantity-locations .sz-fname span.sky-label {
  width: 150px;
}
.sz-quantity-locations .sz-qoh span.sky-label {
  width: 200px;
}
.sz-quantity-locations .sz-qa span.sky-label {
  width: 200px;
}
.sz-quantity-locations .sz-qoh-qa {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sz-quantity-locations .sz-qoh-qa span {
  width: 130px;
  display: inline-block;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #252C32;
}
.sz-quantity-locations .sz-qoh-qa span:last-child {
  font-weight: 400;
  width: 50px
}
/* Location Quantity CSS End */

.sz-um-type-tab .ant-anchor.ant-anchor-fixed {
  width: auto;
  display: inline-flex;
  background: #fefefe;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  padding: 4px;
}
.sz-um-type-tab .ant-anchor.fixed {
  width: auto;
  display: inline-flex;
  background: #fefefe;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  padding: 4px;
}
.sz-um-type-tab .ant-anchor-ink {
  display: none;
}
.sz-um-type-tab .ant-anchor-link {
  padding: 9px 20px 7px;
  background: #F7F9FB;
  border-radius: 40px;
  margin-right: 4px;
  font-weight: 600;
  font-size: 12px;
  
}
.sz-um-type-tab .ant-anchor-link .ant-anchor-link-title {
  color: #4353FF;
  opacity: 1;
}
.sz-um-type-tab .ant-anchor-link-active {
  padding: 9px 20px 7px;
  background: #4353FF;
  border-radius: 40px;
}
.sz-um-type-tab .ant-anchor-link-active > .ant-anchor-link-title,
.sz-um-type-tab .ant-anchor-link-active > .ant-anchor-link-title:hover {
  font-weight: 600;
  font-size: 12px;
  color: #FFFFFF;
  opacity: 1;
}
.sz-um-type-tab .add-more {
  padding: 8px 15px;
  background: none;
}
.sz-um-type-tab .add-more svg {
  fill: #4353FF;
}
.sz-index {
  margin: 3px 10px 0 0;
}
.relation-um-type {
  margin-right: 15px;
  width: 175px;
}
.relation-um-type-input{
  margin-right: 15px;
  width: 130px;
}
@media screen and (max-width: 1199px) {
  .generate-btn {
    text-align: end;
  }
}
@media screen and (max-width: 991px) {
  .gallery-main {
    margin-bottom: 30px;
  }
  .swap-column {
    order: 2;
    margin-top: 15px;
  }
}
@media screen and (max-width: 767px) {
  .sz-last-edited-by span.ant-typography {
    margin: 0 0 10px 0;
    display: block;
  }
  .gallery-main {
    margin: 0 auto 30px;
  }
  .sz-relationship {
    display: block;
  }
  .sz-relationship * {
    display: inline-block;
  }
  .sz-relationship .ant-form-item-control-input,
  .sz-relationship .ant-form-item-control-input-content {
    display: block;
  }
}
@media screen and (max-width: 625px) {
  .sz-relationship * {
    display: block;
  }
  .sz-relationship {
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
  }
  .sz-relationship .sz-equal {
    width: 120px;
    text-align: center;
    margin: 0 0 5px;
  }
  .sz-index span.anticon.anticon-close {
    position: absolute;
    right: 0;
    top: 6px;
  }
}
@media screen and (max-width: 575px) {
  .sz-locations .ant-form-item .ant-form-item-label,
  .sz-locations .ant-form-item .ant-form-item-control {
    max-width: max-content;
  }
}